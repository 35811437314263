
import DropDownButton from "@/components/DropDownButton"
import {
  downloadFile,
  getConvertedUtcDateTimetoTimezone,
} from "@evercam/shared/utils"
import { AiApi } from "@evercam/shared/api/aiApi"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"

export default {
  components: { DropDownButton },
  props: {
    exportParams: {
      type: Object,
      default: () => ({}),
    },
    selectedTab: {
      type: String,
      default: () => "",
    },
    cameraExid: {
      type: String,
      required: true,
    },
    projectExid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formats: [
        {
          name: "CSV (*.csv) ",
          value: "csv",
          logo: require("~/assets/img/csv.png"),
        },
      ],
      isDownloading: false,
      isGeneratingExport: false,
      downloadProgress: 0,
    }
  },
  computed: {
    ...mapStores(useProjectStore),
  },
  methods: {
    async exportAs({ value }) {
      try {
        this.isGeneratingExport = true
        const response = await AiApi.anpr.exportAnprRecords(
          this.projectExid,
          {
            fileType: value,
            reportType: this.selectedTab,
            ...this.exportParams,
          },
          this.onDownloadProgress
        )

        const url = window.URL.createObjectURL(new Blob([response]))
        const filename = `get-${this.selectedTab}-${
          this.cameraExid
        }-${getConvertedUtcDateTimetoTimezone(
          this.exportParams.fromDate,
          this.projectStore.selectedProjectTimezone
        )}-${getConvertedUtcDateTimetoTimezone(
          this.exportParams.toDate,
          this.projectStore.selectedProjectTimezone
        )}.csv`

        downloadFile(url, filename)
        this.saveAnalyticsEvent(value)
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.anpr.export.download_failed"),
          error,
        })
      } finally {
        this.isDownloading = false
        this.downloadProgress = false
      }
    },
    saveAnalyticsEvent(value) {
      const events = {
        csv: `export_anpr_${this.selectedTab}_CSV`,
      }
      if (value in events) {
        this.$analytics.saveEvent(events[value])
      }
    },
    onDownloadProgress(evt) {
      if (evt.loaded) {
        this.isGeneratingExport = false
      }
      this.isDownloading = true
      this.downloadProgress = Math.ceil((evt.loaded / evt.total) * 100)
    },
  },
}
