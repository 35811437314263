
import OpenInRecordingsBtn from "@/components/anpr/OpenInRecordingsBtn"
import PreviewableThumbnail from "@/components/PreviewableThumbnail"
import PlateNumber from "@/components/anpr/PlateNumber"

import {
  isDateWithinRange,
  getConvertedUtcDateTimetoTimezone,
  getFormattedPlateNumber,
} from "@evercam/shared/utils"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { useAccountStore } from "@/stores/account"

export default {
  name: "DefaultTable",
  components: { OpenInRecordingsBtn, PreviewableThumbnail, PlateNumber },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
    records: {
      type: Array,
      default: () => [],
    },
    camera: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapStores(useProjectStore, useAccountStore),
    cameraExid() {
      return this.camera.exid || this.camera.id
    },
    headers() {
      return [
        {
          width: 100,
          divider: true,
          sortable: false,
          text: this.$t("content.anpr.thumbnail"),
          value: "thumbnailUrl",
          class: "header--custom",
          copyable: false,
        },
        {
          width: 80,
          divider: true,
          text: this.$t("content.anpr.plate_number"),
          value: "plateNumber",
          class: "header--custom",
          toStringFn: (item, key) => getFormattedPlateNumber(item[key]),
        },
        {
          divider: true,
          width: 120,
          text: this.$t("content.anpr.direction"),
          value: "direction",
          class: "header--custom",
          toStringFn: (item, key) =>
            this.$t(`content.anpr.directions.${item[key]}`),
        },
        {
          divider: true,
          text: this.$t("content.anpr.capture_time"),
          value: "captureTime",
          class: "header--custom",
          filter: (date) =>
            isDateWithinRange(date, this.filters.fromDate, this.filters.toDate),
          toStringFn: (item, key) => this.getFormattedDate(item[key]),
        },
      ]
    },
    transformedRecords() {
      return (
        this.records.map((record) => ({
          ...record,
          thumbnailUrl: `${this.$config.public.apiURL}/cameras/${this.cameraExid}/anpr/${record.captureTime}/thumbnail?authorization=${this.accountStore.token}`,
        })) || []
      )
    },
  },
  watch: {
    transformedRecords: {
      handler() {
        this.$emit("copy-plates", {
          headers: this.headers,
          plates: this.transformedRecords,
        })
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getFormattedDate(date) {
      return getConvertedUtcDateTimetoTimezone(
        date,
        this.projectStore.selectedProjectTimezone
      )
    },
    getFormattedPlateNumber,
  },
}
