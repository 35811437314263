
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { AnalyticsEvent } from "@evercam/shared/types/analytics"
import { useAccountStore } from "@/stores/account"

export default {
  name: "OpenInRecordingsBtn",
  props: {
    date: {
      type: [String, Object],
      default: "1970-01-01",
    },
  },
  data() {
    return {
      selectedItem: null,
    }
  },
  computed: {
    ...mapStores(useProjectStore),
  },
  methods: {
    getRecordingsUrl(cameraExid) {
      const recordingsTimestamp = this.$moment
        .utc(this.date)
        .tz(this.projectStore.selectedProjectTimezone)
        .format("YYYY-MM-DDTHH:mm:ssZ")

      return `${this.projectStore.projectRoute}/${cameraExid}/recordings/snapshots/${recordingsTimestamp}`
    },
    saveEvent() {
      this.$analytics.saveEvent(AnalyticsEvent.clickGateReportOpenInRecording)
    },
    handleImageError(e) {
      e.target.src = "/unavailable.jpg"
    },
    getCameraThumbnailUrl(cameraExid) {
      const baseUrl = this.$config.public.apiURL
      const queryParams = `authorization=${useAccountStore().token}&view=true`

      return this.$imgproxy.get360pResizedImageUrl(
        `${baseUrl}/cameras/${cameraExid}/thumbnail?${queryParams}`
      )
    },
  },
}
