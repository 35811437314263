
import EventsUtils from "@/components/gateReport/mixins/eventsUtils"
import AnprReport from "@/components/anpr/AnprReport"
import Vue from "vue"
import { AnprQueryParams, AnprReportType } from "@evercam/shared/types/anpr"
import { camelizeKeys } from "humps"
import { useCameraStore } from "@/stores/camera"
import { useProjectStore } from "@/stores/project"
import { mapStores } from "pinia"

export default Vue.extend({
  name: "Anpr",
  components: { AnprReport },
  mixins: [EventsUtils],
  middleware: ["ensureCameraRoute"],
  async asyncData({ app, from, redirect }) {
    if (!app.$permissions.user.can.access.anpr()) {
      redirect(useCameraStore().cameraRoute)
    }

    let tab = AnprReportType.detections
    let internalSortBy, internalSortDesc
    let queryParams: AnprQueryParams = camelizeKeys(
      from.query
    ) as unknown as AnprQueryParams
    let searchParams: AnprQueryParams = {
      limit: 20,
      page:
        queryParams.page !== undefined
          ? Number.parseInt(queryParams.page.toString())
          : 1,
      plateNumber: "",
      from: app.$moment().subtract({ months: 2 }).format("YYYY-MM-DDTHH:mm:ss"),
      toDate: app.$moment().format("YYYY-MM-DDTHH:mm:ss"),
    }

    if (queryParams.direction) {
      searchParams.direction = queryParams.direction
    }

    if (queryParams.plateNumber) {
      searchParams.plateNumber = queryParams.plateNumber
    }

    if (queryParams.tab) {
      tab = queryParams.tab
    }

    if (queryParams.fromDate) {
      searchParams.fromDate = app
        .$moment(queryParams.fromDate)
        .format("YYYY-MM-DDTHH:mm:ss")
    }

    if (queryParams.toDate) {
      searchParams.toDate = app
        .$moment(queryParams.toDate)
        .format("YYYY-MM-DDTHH:mm:ss")
    }

    if (queryParams.sort && queryParams.sort.split("|").length) {
      searchParams.sort = queryParams.sort
      let sort = queryParams.sort.split("|")
      internalSortBy = sort[0]
      internalSortDesc = sort[1] === "desc"
    } else {
      internalSortDesc = true
    }

    return {
      initialParams: {
        tab,
        page: searchParams.page,
        limit: searchParams.limit,
        sort: searchParams.sort,
        plateNumber: searchParams.plateNumber,
        fromDate: app.$moment(searchParams.fromDate).format("YYYY-MM-DD"),
        toDate: app.$moment(searchParams.toDate).format("YYYY-MM-DD"),
        direction: searchParams.direction || "All",
        internalSortBy,
        internalSortDesc,
      },
    }
  },
  head() {
    return {
      title: `${this.cameraStore.selectedCamera?.name || "Evercam"} | ${this.$t(
        "pages.anpr"
      )}`,
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useCameraStore, useProjectStore),
  },
})
