
import OpenInRecordingsBtn from "@/components/anpr/OpenInRecordingsBtn"
import DetectionsChart from "@/components/anpr/DetectionsChart"
import PreviewableThumbnail from "@/components/PreviewableThumbnail"
import PlateNumber from "@/components/anpr/PlateNumber"

import { mapStores } from "pinia"
import {
  getConvertedUtcDateTimetoTimezone,
  getFormattedPlateNumber,
} from "@evercam/shared/utils"
import { useAccountStore } from "@/stores/account"

export default {
  name: "PlatesTable",
  components: {
    OpenInRecordingsBtn,
    PreviewableThumbnail,
    DetectionsChart,
    PlateNumber,
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
    records: {
      type: Array,
      default: () => [],
    },
    camera: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapStores(useAccountStore),
    cameraExid() {
      return this.camera.exid || this.camera.id
    },
    timezone() {
      return this.project.timezone || "Europe/Dublin"
    },
    headers() {
      return [
        {
          width: 100,
          divider: true,
          sortable: false,
          text: this.$t("content.thumbnail"),
          value: "thumbnailUrl",
          class: "header--custom",
          copyable: false,
        },
        {
          width: 100,
          divider: true,
          sortable: false,
          text: this.$t("content.anpr.plate_number"),
          value: "plateNumber",
          class: "header--custom",
          toStringFn: (item, key) => getFormattedPlateNumber(item[key]),
        },
        {
          width: 220,
          sortable: false,
          text: this.$t("content.anpr.total_detections"),
          value: "chart",
          class: "header--custom",
          copyable: false,
        },
        {
          width: 50,
          divider: true,
          sortable: false,
          value: "totalDetections",
          class: "header--custom",
        },
        {
          width: 80,
          divider: true,
          text: this.$t("content.anpr.first_seen"),
          value: "firstSeen",
          class: "header--custom",
          toStringFn: (item, key) => this.getFormattedDate(item[key]),
        },
        {
          divider: true,
          text: this.$t("content.anpr.last_seen"),
          value: "lastSeen",
          class: "header--custom",
          toStringFn: (item, key) => this.getFormattedDate(item[key]),
        },
      ]
    },
    transformedRecords() {
      return (
        this.records.map(({ plateNumber, detections, firstSeen, lastSeen }) => {
          const detectionsEntries = Object.entries(detections)
          const totalDetections = Object.values(detections).reduce(
            (acc, count) => (acc = acc + parseInt(count)),
            0
          )

          return {
            plateNumber: plateNumber,
            detections: detectionsEntries,
            thumbnailUrl: `${this.$config.public.apiURL}/cameras/${
              this.cameraExid
            }/anpr/${this.$moment(lastSeen).format(
              "YYYY-MM-DDTHH:mm:ss"
            )}Z/thumbnail?authorization=${this.accountStore.token}`,
            totalDetections,
            firstSeen: firstSeen,
            lastSeen: lastSeen,
          }
        }) || []
      )
    },
  },
  watch: {
    transformedRecords: {
      handler() {
        this.$emit("copy-plates", {
          headers: this.headers,
          plates: this.transformedRecords,
        })
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getFormattedDate(date) {
      return getConvertedUtcDateTimetoTimezone(date, this.timezone)
    },
    getFormattedPlateNumber,
  },
}
