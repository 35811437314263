
export default {
  name: "DetectionsChart",
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    from: {
      type: [Object, String],
      default: () => new Date().toISOString(),
    },
    to: {
      type: [Object, String],
      default: () => new Date().toISOString(),
    },
  },
  data() {
    return {
      chartDialog: false,
    }
  },
  computed: {
    chartData() {
      let items = this.item.detections.map(([date, count]) => [date, count])

      // Ensure first date
      if (
        items[0] &&
        items[0][0] !== this.$moment(this.from).format("YYYY-MM-DD")
      ) {
        items = [[this.$moment(this.from).format("YYYY-MM-DD"), 0], ...items]
      }

      // Ensure last date
      if (
        items[items.length - 1] &&
        items[items.length - 1][0] !==
          this.$moment(this.to).format("YYYY-MM-DD")
      ) {
        items = [...items, [this.$moment(this.to).format("YYYY-MM-DD"), 0]]
      }

      // Fill in missing days
      return items.reduce((acc, [date, count], index, arr) => {
        const nextDate = arr[index + 1] ? arr[index + 1][0] : undefined
        const datesInBetween = this.getDaysRange(date, nextDate).map((d) => [
          d.format("DD/MM"),
          0,
        ])

        return [
          ...acc,
          [this.$moment(date).format("DD/MM"), count],
          ...datesInBetween,
        ]
      }, [])
    },
    barWidth() {
      const width = 100 / this.chartData.length

      return width < 15 && width > 3.5 ? width : 3.5
    },
  },
  methods: {
    getDaysRange(startDate, endDate) {
      const start = this.$moment(startDate)
      const end = this.$moment(endDate)
      const difference = end.diff(start, "days") - 1
      if (difference <= 0 || !startDate || !endDate) {
        return []
      }

      return new Array(difference).fill().map((_, index) => {
        return start.clone().add(index + 1, "days")
      })
    },
  },
}
