
import DefaultTable from "@/components/anpr/tables/DefaultTable"
import MatchesTable from "@/components/anpr/tables/MatchesTable"
import PlatesTable from "@/components/anpr/tables/PlatesTable"
import RightSidebarContent from "@/components/portals/RightSidebarContent"
import CopyTableToClipboard from "@evercam/shared/components/CopyTableToClipboard"
import { updateQuery, debounce } from "@evercam/shared/utils"
import { decamelize } from "humps"
import EventsUtils from "@/components/gateReport/mixins/eventsUtils"
import ExportAsBtn from "@/components/anpr/ExportAsBtn"
import Vue, { PropType } from "vue"
import { AiApi } from "@evercam/shared/api/aiApi"
import { useLayoutStore } from "@/stores/layout"
import { mapStores } from "pinia"
import { AnprReportType } from "@evercam/shared/types"
import { DataOptions as VDataTableOptions } from "vuetify/types"

export type AnprInitialParams = {
  tab?: number | string
  page?: number | string
  limit?: number | string
  sort?: string
  plateNumber?: string
  fromDate?: string
  toDate?: string
  direction?: string
  internalSortBy?: string
  internalSortDesc?: boolean
}

export default Vue.extend({
  name: "Anpr",
  components: {
    CopyTableToClipboard,
    ExportAsBtn,
    RightSidebarContent,
    DefaultTable,
    MatchesTable,
    PlatesTable,
  },
  mixins: [EventsUtils],
  middleware: ["ensureCameraRoute"],
  props: {
    initialParams: {
      type: Object as PropType<AnprInitialParams>,
      default: () => ({} as AnprInitialParams),
    },
    camera: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    syncQueryParams: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      anprReportType: AnprReportType,
      tab: this.initialParams.tab || "",
      page: this.initialParams.page || 1,
      limit: this.initialParams.limit || 20,
      sort: this.initialParams.sort || "",
      plateNumber: this.initialParams.plateNumber || "",
      fromDate: this.initialParams.fromDate || "",
      toDate: this.initialParams.toDate || "",
      direction: this.initialParams.direction || "All",
      internalSortBy: this.initialParams.internalSortBy || "",
      internalSortDesc: this.initialParams.internalSortDesc || true,
      total: 0,
      records: [],
      initialLoad: true,
      defaultSorts: {
        [AnprReportType.detections]: "capture_time",
        [AnprReportType.matches]: "inTime",
        [AnprReportType.plates]: "last_seen",
      },
      dates: [""],
      directionItems: ["All", "forward", "reverse"],
      dateMenu: "",
      startDateMenu: false,
      endDateMenu: false,
      isLoading: false,
      isProgress: false,
      options: {},
      isTabChange: false,
      tableHeight: "70vh",
      allowedSorts: {
        [AnprReportType.detections]: [
          "plateNumber",
          "direction",
          "captureTime",
        ],
        [AnprReportType.matches]: ["inTime", "outTime", "plateNumber"],
        [AnprReportType.plates]: ["firstSeen", "lastSeen", "plateNumber"],
      },
      headers: [],
      copiedPlates: [],
      exportParams: {},
      providers: {
        [AnprReportType.plates]: AiApi.anpr.getAnprPlates,
        [AnprReportType.matches]: AiApi.anpr.getAnprMatched,
        [AnprReportType.detections]: AiApi.anpr.getAnprEvents,
      },
    }
  },
  head() {
    return {
      title: `${this.camera?.name || "Evercam"} | ${this.$t("pages.anpr")}`,
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useLayoutStore),
    cameraExid() {
      return this.camera?.exid || this.camera?.id
    },
    projectExid() {
      return this.project.exid
    },
    timezone() {
      return this.project.timezone || "Europe/Dublin"
    },
    formattedStartDate() {
      let date
      if (this.fromDate) {
        date = this.$moment(this.fromDate).format("DD/MM/YYYY")
      } else {
        date = this.$moment(new Date())
          .subtract({ months: 2 })
          .format("DD/MM/YYYY")
      }

      return date
    },
    formattedEndDate() {
      return this.$moment(this.toDate || new Date()).format("DD/MM/YYYY")
    },
    startDateMenuRef() {
      return this.$refs.startDateMenu as Vue & { save: (date: string) => void }
    },
    endDateMenuRef() {
      return this.$refs.endDateMenu as Vue & { save: (date: string) => void }
    },
    formattedPlateNumber() {
      return this.plateNumber.replace(/[-]/g, "")
    },
  },
  watch: {
    plateNumber: debounce(function () {
      // @ts-ignore
      this.search()
    }, 500),
    fromDate() {
      this.debouncedSearch()
    },
    toDate() {
      this.debouncedSearch()
    },
    options: {
      handler(val, previousVal) {
        if (JSON.stringify(val) === JSON.stringify(previousVal)) {
          return
        }
        this.debouncedSearch()
      },
      deep: true,
    },
    tab() {
      this.isTabChange = true
      this.debouncedSearch()
    },
    direction() {
      this.debouncedSearch()
    },
  },
  mounted() {
    this.layoutStore.enableRightSidebar()
    this.updateUrl()
  },
  beforeDestroy() {
    this.layoutStore.disableRightSidebar()
  },
  methods: {
    debouncedSearch: debounce(
      function () {
        // @ts-ignore
        this.search()
      },
      500,
      true
    ),
    async search(): Promise<void> {
      this.isLoading = true
      try {
        const { page } = this.options as VDataTableOptions
        const direction = this.direction === "All" ? "" : this.direction
        if (this.isTabChange || !this.internalSortBy) {
          const sortBy = this.defaultSorts[this.tab]
          this.sort = `${sortBy}|desc`
          this.internalSortBy = sortBy
          this.internalSortDesc = true
        } else {
          this.sort = `${this.internalSortBy}|${
            this.internalSortDesc ? "desc" : "asc"
          }`
        }

        const fromDate = this.$moment(this.fromDate).format("YYYY-MM-DD")
        const toDate = this.$moment(this.toDate).format("YYYY-MM-DD")
        const timezone = this.timezone

        const formatDate = (date) =>
          this.$moment.tz(date, timezone).utc().format("YYYY-MM-DDTHH:mm:ssZ")

        const directionParam =
          this.tab === this.anprReportType.detections ? { direction } : {}

        this.exportParams = {
          fromDate: formatDate(`${fromDate}T00:00:00`),
          toDate: formatDate(`${toDate}T23:59:59`),
          plateNumber: this.formattedPlateNumber,
          sort: decamelize(this.sort),
          camerasExid: [this.cameraExid],
          ...directionParam,
        }

        const params = {
          page: page || 1,
          limit: this.limit,
          ...this.exportParams,
          ...directionParam,
        }

        const { items, total } = await this.providers[this.tab](
          this.projectExid,
          params
        )
        this.records = items
        this.total = total
        this.page = page
        this.updateUrl()
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.generic_error_message"),
          error,
        })
      }
      this.isLoading = false
    },
    updateUrl() {
      if (!this.syncQueryParams) {
        return
      }
      updateQuery({
        page: `${this.page || 1}`,
        limit: `${this.limit}`,
        direction: this.direction,
        plateNumber: this.formattedPlateNumber,
        fromDate: this.fromDate,
        toDate: this.toDate,
        tab: `${this.tab}`,
        sort: this.sort,
      })
    },
    onOptionsChange({ sortBy, sortDesc, itemsPerPage }) {
      this.limit = itemsPerPage
      if (!this.isSortByAllowed(sortBy[0]) || this.isTabChange) {
        this.isTabChange = false

        return
      }
      if (sortDesc[0] !== this.internalSortDesc) {
        this.internalSortDesc = sortDesc[0]
      }
      if (sortBy[0] !== this.internalSortBy) {
        this.internalSortBy = sortBy[0]
      }
    },
    onResize() {
      this.$setTimeout(() => {
        // @see https://stackoverflow.com/questions/70031750/property-el-does-not-exist-on-type-never
        const tableTop =
          (
            this.$refs.tableContainer as Vue & { $el: HTMLElement }
          )?.$el?.getBoundingClientRect()?.top || 0
        const defaultFooterHeight = 40
        this.tableHeight = (
          window.innerHeight -
          tableTop -
          defaultFooterHeight
        ).toString()
      }, 500)
    },
    isSortByAllowed(sortBy) {
      return this.allowedSorts[this.tab].reduce(
        (acc, s) => acc || sortBy === s || sortBy === decamelize(s),
        false
      )
    },
    handlePlatesCopy({ headers, plates }) {
      this.headers = headers
      this.copiedPlates = plates
      this.onResize()
    },
  },
})
