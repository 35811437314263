
import OpenInRecordingsBtn from "@/components/anpr/OpenInRecordingsBtn"
import PreviewableThumbnail from "@/components/PreviewableThumbnail"
import PlateNumber from "@/components/anpr/PlateNumber"
import { mapStores } from "pinia"
import {
  getConvertedUtcDateTimetoTimezone,
  getFormattedPlateNumber,
} from "@evercam/shared/utils"
import { useAccountStore } from "@/stores/account"

export default {
  name: "MatchesTable",
  components: { OpenInRecordingsBtn, PreviewableThumbnail, PlateNumber },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
    records: {
      type: Array,
      default: () => [],
    },
    camera: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapStores(useAccountStore),
    cameraExid() {
      return this.camera.exid || this.camera.id
    },
    timezone() {
      return this.project.timezone || "Europe/Dublin"
    },
    headers() {
      return [
        {
          width: 100,
          divider: true,
          sortable: false,
          text: this.$t("content.anpr.plate_number"),
          value: "plateNumber",
          class: "header--custom",
          toStringFn: (item, key) => getFormattedPlateNumber(item[key]),
        },
        {
          width: 80,
          divider: true,
          sortable: false,
          text: this.$t("content.anpr.thumbnail_in"),
          value: "thumbnailIn",
          class: "header--custom",
          copyable: false,
        },
        {
          width: 100,
          divider: true,
          sortable: false,
          text: this.$t("content.anpr.thumbnail_out"),
          value: "thumbnailOut",
          align: "center",
          class: "header--custom",
          copyable: false,
        },
        {
          width: 80,
          divider: true,
          text: this.$t("content.anpr.timestamp_in"),
          value: "inTime",
          class: "header--custom",
          toStringFn: (item, key) => this.getFormattedDate(item[key]),
        },
        {
          divider: true,
          text: this.$t("content.anpr.timestamp_out"),
          value: "outTime",
          class: "header--custom",
          toStringFn: (item, key) => this.getFormattedDate(item[key]),
        },
      ]
    },
    transformedRecords() {
      return (
        this.records?.map((record) => ({
          ...record,
          thumbnailIn: `${this.$config.public.apiURL}/cameras/${this.cameraExid}/anpr/${record?.inTime}/thumbnail?authorization=${this.accountStore.token}`,
          thumbnailOut: `${this.$config.public.apiURL}/cameras/${this.cameraExid}/anpr/${record?.outTime}/thumbnail?authorization=${this.accountStore.token}`,
        })) || []
      )
    },
  },
  watch: {
    transformedRecords: {
      handler() {
        this.$emit("copy-plates", {
          headers: this.headers,
          plates: this.transformedRecords,
        })
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getFormattedDate(date) {
      return getConvertedUtcDateTimetoTimezone(date, this.timezone)
    },
    getFormattedPlateNumber,
  },
}
